/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app) => {
        const
            $loadMoreBtn = $('.load-more'),
            $postsContainer = $('.container-news'),
            $loadMoreContainer = $('.load-more-container'),
            $loadMore = $('.load-more'),
            $loaderContainer = $('.loader-container')
        ;

        let xhr = null;



        // filters
        $('.filter-container').on('click', '.filter-item', function() {
            $(this).toggleClass('active');

            filterNews();
        });

        $('.filter-select').on('change', 'select', function() {
            filterNews();
        });

        function filterNews() {
            abort(xhr);

            let url = `/ajax/posts/category`;

            let data = {
                categories: [],
                date: '',
            };

            $('.filter-item.active').each(function() {
                data.categories.push($(this).data('slug'))
            });

            data.date = $('.filter-select select').val();

            xhr = $.ajax({
                url: url,
                type: 'POST',
                method: 'POST',
                dataType: 'json',
                data: data,
                beforeSend: () => {
                    $postsContainer.empty();
                    $postsContainer.append('<div class="col-12 ta-c"><div class="lds-dual-ring"></div></div>');

                    $loadMoreContainer.addClass('d-none');
                },
                success: (response, status) => {
                    $postsContainer.empty();
                    $postsContainer.append(response);

                    $loadMoreContainer.removeClass('d-none');

                    xhr = null;
                },
                error: (response, status, error) => {
                    console.log(error);
                }
            });
        }



        // load more
        $loadMoreBtn.on('click', function(e){
            e.preventDefault();

            abort(xhr);

            let offset = $postsContainer.find('.custom-card').length;
            let url = `/ajax/posts/${offset}/`;

            let data = {
                categories: [],
                date: '',
            };

            $('.filter-item.active').each(function() {
                data.categories.push($(this).data('slug'))
            });

            data.date = $('.filter-select select').val();

            xhr = $.ajax({
                url: url,
                type: 'POST',
                method: 'POST',
                dataType: 'json',
                data: data,
                beforeSend: () => {
                    $postsContainer.prepend('<div class="col-12 ta-c"><div class="lds-dual-ring"></div></div>');
                },
                success: (response, status) => {
                    $postsContainer.find('.lds-dual-ring').remove();

                    $postsContainer.append(response);

                    if($(response).find('.no-more-post').length) {
                        $loadMoreContainer.remove();
                    }

                    xhr = null;
                },
                error: (response, status, error) => {
                    console.log(error);
                }
            });

        });

        /*
        | HELPER
        |
        | abort ajax request
        |------------
        */
        function abort(xhrVar, $list = null){
            if (xhrVar !== null) {
                xhrVar.abort();
            }

            if($list !== null){
                $list.find('.loading--ajax').hide();
            }
        }
    }
}

