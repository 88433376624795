/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, customGoogleMap) => {
        /*
        |
        | Constants
        |------------
        */
        const
            $map = $('#map')
        ;


        /*
		|
		| initGoogleMap
		|----------------
		|
		*/
    const $email_input = $('#input_1_22');
    const $select_input = $('#input_1_1')
        $select_input.on('change',function(){
            $email_input.val($select_input.children("option:selected").val())
        })

        $.each($map, function () {
            var $map = $(this);

            var map = new customGoogleMap($map, {
                'markers': $map.find('.marker'),
                'zoom': 16
            });
        });

        var rules = [
            {
                'mail': 'mail1',
                'zipCodes': ['59', '62', '02', '08', '51', '52', '54', '55', '57', '67', '68', '80', '88']
            },
            {
                'mail': 'mail2',
                'zipCodes': ['14','22','29','35','37','41','44','49','50','53','56','61','72','85']
            },
            {
                'mail': 'mail3',
                'zipCodes': ['10','21','27','28','45','60','75','76','77','78','89','91','92','93','94','95','971','972','973','974','976']
            },
            {
                'mail': 'mail4',
                'zipCodes': ['03','15','16','18','19','23','24','36','46','63','81','86','87']
            },
            {
                'mail': 'mail5',
                'zipCodes': ['01','25','38','39','43','58','69','70','71','73','74','90']
            },
            {
                'mail': 'mail6',
                'zipCodes': ['09','17','31','32','33','40','47','64','65','79','82']
            },
            {
                'mail': 'mail7',
                'zipCodes': ['04','05','06','07','11','12','13','26','30','34','42','48','66','83','84','2A','2B']
            }, 
        ]



        $('#gform_1').on('submit', function(event){ 
            event.preventDefault();
            if ($select_input[0].selectedIndex > 0 && $select_input[0].selectedIndex < 4) {
                var zipCode = $('#input_1_27')[0].value
                rules.map(function (rule) {
                    if (rule.zipCodes.indexOf(zipCode) > -1) {
                        $('#input_1_26').val(rule.mail)
                    }
                })
            }
            $('#gform_1')[0].submit();
        })

    }
}

