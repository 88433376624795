/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
  init: (app, Menu, Kira, ScrollMagic) => {
    /*
    |
    | Constants
    |-----------
    */
    const
      $body = $('body'),
      $pageLoader = $('.page-loader'),
      scrollAreas = document.querySelectorAll('.scroll-area'),
      $menuWrapper = $('#mobile-menu'),
      $menuButton = $('#header .btn-menu'),
      $menuBars = $menuButton.find('.item-burger > span'),
      $menuClose = $menuWrapper.find('.item-close'),
      $menuUlChildren = $menuWrapper.find('.container-nav-drop ul'),
			$menuLiChildren = $menuWrapper.find('.item-has-child')


    /*
        |
        | Scroll Areas
        |---------------
        */
    Array.prototype.forEach.call(scrollAreas, element => {
      Scrollbar.init(element);
    });




    /*
		|
		| Menu
		|-------
        */
    const menu = new Menu($menuWrapper, $menuButton, {
      reverseTimeScale: 2
    });

    menu.menuTimeline
      .to($menuBars.eq(1), 0.3, {
        autoAlpha: 0
      }, 'start')
      .to($menuBars.eq(0), 0.5, {
        x: 0,
        y: 8,
        rotation: 45,
        ease: Power1.easeOut
      }, 'start')
      .to($menuBars.eq(2), 0.5, {
        x: 0,
        y: -8,
        rotation: -45,
        ease: Power1.easeOut
      }, 'start')


    menu.init();

    $menuWrapper.on('menu:open', function() {
      if (this.classList.contains('closed')) {
        this.classList.remove('closed')
      }
      this.classList.add('open')

      document.querySelector('#header').classList.add('menu-open')
    })

    $menuWrapper.on('menu:closed', function() {
      if (this.classList.contains('open')) {
        this.classList.remove('open')
      }
      this.classList.add('closed')

      document.querySelector('#header').classList.remove('menu-open')
    })

    $menuUlChildren.click(function() {
      const li = $(this).find('li.hidden')
			li.toggle('hidden')
    });
		$menuLiChildren.click(function() {
			const that = this.closest('.menu-item')
      const li = $(that).find('li.hidden')
			li.toggle('hidden')
    });


    /*
		|
		| Kira
		|-------
        */
    const kira = new Kira(ScrollMagic, {
      debug: false,
      loadEvent: {
        domElement: $body,
        eventName: 'loaderEnd'
      },
      optimize: true,
      options: {
        start: '-=0.6',
        triggerHook: 'onEnter'
      }
    });

    /*
		| fadeInUp.parallax.sm
		|-----------------------
        */
    kira.add('fadeInUp', ($item, timeline, start) => {
      timeline.from($item, 0.8, {
        y: 30,
        autoAlpha: 0,
        ease: Power1.easeOut
      }, start)
    });

    /*
		| fadeInUp.parallax.sm
		|-----------------------
        */
    kira.add('fadeInUp.parallax.sm', ($item, timeline, start) => {
      timeline.from($item, 0.8, {
        y: 100,
        autoAlpha: 0,
        ease: Sine.easeOut
      }, start)
    });

    /*
		| fadeInUp.parallax
		|--------------------
        */
    kira.add('fadeInUp.parallax', ($item, timeline, start) => {
      timeline.from($item, 1, {
        y: 80,
        autoAlpha: 0,
        ease: Power1.easeOut
      }, start)
    });

    /*
		| fadeInUp.parallax.sm
		|-----------------------
        */
    kira.add('fadeInLeft.stagger.sm', ($item, timeline, start) => {
      timeline.staggerFrom($item.find('[data-stagger-item]'), 0.6, {
        autoAlpha: 0,
        x: 20,
        ease: Power1.easeOut
      }, '0.1', start)
    });

    /*
		| fadeInUp.parallax
		|--------------------
        */
    kira.add('scaleX', ($item, timeline, start) => {
      timeline.from($item, 1, {
        scaleX: 0,
        transformOrigin: 'left top',
        ease: Expo.easeInOut
      }, start)
    });

    kira.init();
  }
}

